import { defineStore } from 'pinia'

export const vendorStore = defineStore('vendor', {
  state: () => ({
     resturant: [],
     cateogries: [],
     workingDays:[],
     tiffin_delivery_time:[],
     tables:[],
     single_menue_products:[],
     delivery_charges:[],
     pages_statues:{},
     vender_id:21,  // himalayashishalounge vendor id = 11 ,// lahori vendor id 7
    //  vender_version:3 // frontend pakistan vendor id 9,sweetcity vendor id 15
     base_url:'https://ozpos.au/api/',
    //  base_url:'https://v4.ozfoodz.com.au/api/', // only for vendor 9 
     resturantStatus:JSON.parse(localStorage.getItem('vendorStatus')),
     homePageURL:localStorage.getItem('homeUrl') || '/',
     version:'1.3.5',
  }),  
  actions: { 
    async vednorStorage(vendor) { 
        this.resturant = vendor.data.vendor;
        this.workingDays = vendor.data.WorkDays;
        this.cateogries = vendor.data.MenuCategory;
        this.tables = vendor.data.tables;
        this.tiffin_delivery_time = vendor.data.tiffin_delivery_time
        this.delivery_charges = vendor.data.delivery_charges.charges
        
    },  
    async storePagesStatus(obj){
        this.pages_statues = obj;
    },
    async vendorType(url){ 
      this.homePageURL = url;
       
    }
  }, 
  persist: {
    paths: ['workingDays','resturant','cateogries','homePageURL','tiffin_delivery_time','delivery_charges','pages_statues'],
  },
});